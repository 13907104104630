// import { set } from 'lodash';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'loda... Remove this comment to see the full error message
import { set } from 'lodash';
import setting from './settings';

export default (() => {
  return {
    SEARCH: setting.api.url + 'category/search',

    AUTH: setting.api.AUTH,
    ADMIN_LOGIN: setting.api.url + 'admin/login',
    ADMIN_MFA_DETAILS: setting.api.url2 + 'auth/admin-mfa-details',
    ADMIN_VERIFY_MFA: setting.api.url2 + 'auth/admin-verify-mfa-token',
    ADMIN_FORGOT_PASSWORD: setting.api.url + 'admin/forget-password',
    ADMIN_RESET_PASSWORD: setting.api.url + 'admin/reset-password',
    ADMIN_VARIFY_TOKEN: setting.api.url + 'admin/verify-token',
    ADMIN_LOGOUT: setting.api.url + 'admin/logout',

    GET_ALL_CATEGORIES: setting.api.url + 'category/get-all-categories',
    DELETE_CATEGORY: setting.api.url + 'category/delete',
    GET_USER_CATEGORIES: setting.api.url + 'category',
    UPDATE_CATEGORY: setting.api.url + 'category/update',
    ADD_CATEGORY: setting.api.url + 'category/add',
    GET_CATEGORY: setting.api.url + 'category/get-category-list',
    GET_SUB_CATEGORY: setting.api.url + 'category/get-subcategory-list',
    GET_SKILLS: setting.api.url + 'category/get-skills',

    GET_CMS: setting.api.url + 'cms/get',
    ADD_CMS: setting.api.url + 'cms/add',
    UPDATE_CMS: setting.api.url + 'cms/update',

    USERS_BY_ROLE: setting.api.url + 'user/get-by-role',
    USER_VERIFY_DOCUMENT: setting.api.url + 'user/verify-document',
    GET_USER_PROFILE: setting.api.url + 'user/get/profile',

    CREATE_ADMIN: setting.api.url + 'admin/create',
    GET_ADMIN: setting.api.url + 'admin/get',
    DELETE_ADMIN: setting.api.url + 'admin/delete',
    UPDATE_ADMIN: setting.api.url + 'admin/update',

    // Project
    GET_ADMIN_PROJECT_LIST: setting.api.url + 'admin/project-list',
    GET_USER_PROJECT_DETAIL: setting.api.url + 'user/get/project',
    ADMIN_UPDATE_PROJECT_STATUS: setting.api.url + 'admin/approve/project',

    ACCOUNT_STATUS: setting.api.url + 'admin/block/user',

    GET_ADMIN_PROPOSAL: setting.api.url + 'admin/proposal',
    SEARCH_ADMIN_PROPOSAL: setting.api.url + 'admin/search/proposal',
    GET_VIEW_ADMIN_PROPOSAL: setting.api.url + 'admin/get/proposal',

    // Services
    GET_USER_SERVICE: setting.api.url + 'admin/service-list',
    GET_USER_SERVICE_DETAILS: setting.api.url + 'user/get/service',
    SERVICE_STATUS_UPDATE: setting.api.url + 'admin/change/services',
    MULTI_DELETE_SERVICES: setting.api.url + 'admin/action/multi-delete',

    // Single Image upload
    IMAGE_UPLPOAD: setting.api.url + 'common/singleFile',

    // Articles
    ADMIN_ADD_ARTICLE: setting.api.url + 'admin/add/article',
    ADMIN_CHANGE_PASSWORD: setting.api.url + 'admin/change-password',
    ADMIN_LIST_ARTICLE: setting.api.url + 'admin/list/article',
    ADMIN_STATUS_UPDATE: setting.api.url + 'admin/verify/article',
    GET_ARTICLE_DETAILS: setting.api.url + 'admin/id',
    UPDATE_ARTICLE: setting.api.url + 'admin/update/article',
    ARTICLE_DELETE: setting.api.url + 'admin/delete/article',

    // Help and support
    CONTACT_US: setting.api.url + 'support/admin/get-queries',
    REPLY_CONTACT_US: setting.api.url + 'support/admin/respondOnQuery',
    VIEW_CONTACT_US: setting.api.url + 'support/admin/getQueryById',

    // Portfolio
    GET_PORTFOLIO: setting.api.url + 'admin/portfolio-list',
    PORTFOLIO_STATUS_UPDATE: setting.api.url + 'admin/change/portfolio',
    VIEW_PORTFOLIO: setting.api.url + 'user/get/portfolio',

    // 'GET_SKILLS': setting.api.url + 'category/get-skills',

    //Blogs
    ADD_BLOGS: setting.api.url + 'admin/add/blog',
    GET_BLOGS: setting.api.url + 'admin/blogs-list',
    BLOGS_STATUS: setting.api.url + 'admin/change/blog',
    GET_BLOG_DETAIL: setting.api.url + 'admin/get/blog',
    UPDATE_BLOGS: setting.api.url + 'admin/update/blog',

    //Dispute
    GET_DISPUTE_LIST: setting.api.url + 'dispute/dispute-list',
    GET_DISPUTE: setting.api.url + 'dispute/get',
    UPDATE_DISPUTE: setting.api.url + 'dispute/change',

    //Transaction
    GET_DOBS_LIST: setting.api.url + 'dobs/getAllAccount',
    GET_DOBS_TRANSACTIONS_LIST: setting.api.url + 'dobs/get/transactionList',
    GET_TRANSACTION_LIST: setting.api.url + 'stripe/transaction-list',
    GET_DOBS_TRANSACTION_LIST: setting.api.url + 'dobs/latest-dobs-transaction',
    GET_TRANSACTION_DETAIL: setting.api.url + 'stripe/transaction-detail',
    GET_DOBS_DETAILS: setting.api.url + 'dobs/getAccountInfoByUserId',
    GET_TRANSACTION_HISTORY_LIST: setting.api.url + 'admin/transaction/all/user',
    GET_TRANSACTION_HISTORY_DETAIL: setting.api.url + 'admin/transaction',

    // testimonial and reviews
    GET_TESTIMONIAL: setting.api.url + 'testimonial/get-all-testimonial',
    GET_CLIENT_REVIEW: setting.api.url + 'review/getReviewList',
    PUBLISH_PUBLIC_REVIEW: setting.api.url + 'testimonial/statusUpdate',
    PUBLISH_CLIENT_REVIEW: setting.api.url + 'review/statusChange',
    DELETE_CLIENT_REVIEW: setting.api.url + 'review/admin/delete-review',
    VIEW_DETAILS_OF_CLIENT_REVIEW: setting.api.url + 'review/admin/getReviewByUserId',
    GET_CLIENT_REVIEW_BY_ID: setting.api.url + 'review/getReviewByReviewId',
    DELETE_PUBLIC_TESTIMONIAL: setting.api.url + 'testimonial/admin/delete-review',
    VIEW_DETAILS_OF_PUBLIC_REVIEW: setting.api.url + 'testimonial/admin/getTestimonialById',
    GET_ALL_TOP_TALENT_LIST: setting.api.url + 'review/get-talent-list',
    STATUS_CHANGE_FOR_TOP_TALENT: setting.api.url + 'review/talentStaus-update',
    DELETE_REPLY: setting.api.url + 'review/deleteReply',
    GET_TOP_TALENT_DETAILS: setting.api.url + 'review/admin/view-talent',

    //Promocode
    ADD_PROMOCODE: setting.api.url + 'dobs/create/promoCode',
    GET_LIST_PROMOCODE: setting.api.url + 'dobs/getAllPromoCode',
    GET_PROMOCODE: setting.api.url + 'dobs/get-promoCode',
    UPDATE_PROMOCODE: setting.api.url + 'dobs/update-promocode',
    DELETE_PROMOCODE: setting.api.url + 'dobs/delete-promocode',

    //DashBoard
    GET_DASHBOARD_DETAILS: setting.api.url + 'dashboard/dashboard',

    // ads
    ADD_ADS: setting.api.url + 'ads/admin/add-ads',
    GET_ALL_ADS: setting.api.url + 'ads/admin/adsList',
    DELETE_ADS_SINGLE: setting.api.url + 'ads/admin/deleteAds',
    UPDATE_ADS: setting.api.url + 'ads/admin/updateAds',
    GET_SINGLE_AD: setting.api.url + 'ads/admin/getAds',

    // deletAll multi delete
    MULTIPLE_DELETE: setting.api.url + 'admin/action/multi-delete',

    // Payment request module
    GET_PAYMENT_REQUEST_LIST: setting.api.url + 'paid/request-list',
    GET_SINGLE_PAYMENT_REQUEST: setting.api.url + 'paid/get-request',
    REJECT_PAYMENT_REQUEST: setting.api.url + 'paid/reject-request',
    PAYOUT_PAYMENT: setting.api.url + 'wallet/payout',
    PAYOUT_PAYMENT_PAYONEER: setting.api.url + 'wallet/payoutPayoneer',

    // Service charges
    GET_ALL_SERVICE_CHARGE: setting.api.url + 'service-charge/list',
    ADD_SERVICE_CHARGE: setting.api.url + 'service-charge/add',
    SERVICE_CHARGE_DETAILS_VIEW: setting.api.url + 'service-charge/admin-view',
    UPDATE_SERVICE_CHARGE: setting.api.url + 'service-charge/update',
    DELETE_SERVICE_CHARGE: setting.api.url + 'service-charge/delete',

    //FAQs
    ADD_FAQs: setting.api.url + 'faq/admin/create-faq',
    LIST_FAQs: setting.api.url + 'faq/admin/faq-list',
    UPDATE_FAQs: setting.api.url + 'faq/admin/faq-update',
    DELETE_FAQs: setting.api.url + 'faq/admin/delete-faq',
    GET_FAQs: setting.api.url + 'faq//admin/get-faq',

    // Email Lists
    GET_EMAIL_LIST: setting.api.url2 + 'email-list/get-all-email-lists',
    GET_EMAILS_FROM_LIST: setting.api.url2 + 'email-list/get-email-list',
    EDIT_EMAIL_LIST_ITEM: setting.api.url2 + 'email-list/edit-email-item',
    APPROVE_USER_ON_WAITLIST: setting.api.url2 + 'email-list/approve-user-on-waitlist',

    // Free Pik
    GET_FREEPIK_PLAN_INFO: setting.api.url2 + 'freepik/freepik-plan-info',
  };
})();
